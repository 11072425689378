import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import MyBrotherRTS from '../images/mbrtsimg.jpg';
import SEO from '../components/seo';
import LineFull from '../images/linefull.jpg';
import '../styles/app.scss';

const NonProfit = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            {/* <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[2].description
                }
            /> */}
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section className="page-section smallestwdt">
                <div className="container">
                    <img
                        src={
                            post.featured_media.localFile.childImageSharp.fluid
                                .src
                        }
                        alt="mbrtsimg"
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.content,
                        }}
                    />
                    <img
                        className="nonprofline"
                        src={LineFull}
                        alt="line full"
                    />
                    <a
                        href={post.acf.mbrts_website_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="wrtsbtn yellowbtn biggerbtn"
                    >
                        VISIT THE MBRTS WEBSITE
                    </a>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default NonProfit;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
            featured_media {
                localFile {
                    childImageSharp {
                        fluid {
                            srcWebp
                            src
                        }
                    }
                }
            }
            acf {
                mbrts_website_link
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
